"use client";
import { melkontechUtilit } from "@/utility";
import { useEffect, useState } from "react";
import Image from 'next-image-export-optimizer';
import darkLogo from '@img/logo/Melkon Logo.png'
import Link from "next/link";
// import TagManager from "react-gtm-module";


const Header = ({ dark }) => {
  const [isToggled, setToggled] = useState(false);
  const toggleTrueFalse = () => {
    // TODO: Uncimment
    // TagManager.dataLayer({
    //   dataLayer: {
    //     event: "submit_form",

    //     formName: "Contact Form",
    //     name,
    //     email,
    //     message,
    //     // { name, email, message },
    //   },
    // });
    setToggled(!isToggled);
    melkontechUtilit.openNav(!isToggled);
  };

  useEffect(() => {
    melkontechUtilit.audioSoundAndOpen();
    melkontechUtilit.stickyNav();
    melkontechUtilit.scrollToActiveNav();
  });
  return (
    <header className="melkontech_tm_topbar fixed top-0 left-0 right-0 z-[10] translate-y-[-100%] transition-all duration-[400ms]">
      <div className="wrapper w-full h-auto clear-both float-left transition-all duration-300">
        <div className="topbar_inner w-full h-auto clear-both flex items-center justify-between py-[30px] pr-[37px] pl-[50px] transition-all duration-300">
          <div className="logo">
            <Link href="/" title="Melkon Hovhannisyan's official website" aria-label="Homepage">
              <Image
                className=""
                // src={
                //   dark ? "assets/img/logo/logo.png" : darkLogo
                // }
                src={darkLogo}
                placeholder="blur"
                width={250}
                priority
                alt="Melkon Hovhannisyan - CTO & Co-Founder Brand Logo"
              />
            </Link>
          </div>
          <nav className="menu flex items-center">
            <div className="list pr-[40px]">
              <ul className="anchor_nav" id="anchor_nav">
                {[
                  { id: '#home', label: 'Home' },
                  { id: '#projects', label: 'Projects' },
                  { id: '#skills', label: 'Skills' },
                  { id: '#timeline', label: 'Timeline' },
                  { id: '#connect', label: 'Connect' },
                  { id: '#news', label: 'News' },
                  { id: '#contact', label: 'Contact' },
                  { id: 'https://blog.melkon.tech/', label: 'Blog', isExternal: true }
                ].map(({ id, label, isExternal }) => (
                  <li
                    key={id}
                    className="mr-[20px] inline-block opacity-0 invisible relative left-[10px] transition-all duration-300"
                  >
                    <Link
                      className="text-black font-arimo transition-all duration-300"
                      href={`${id}`}
                      aria-label={`Go to ${label}`}
                      target={isExternal ? '_blank' : '_self'}
                    >
                      {label}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
            <button
              className="trigger leading-[1]"
              onClick={() => toggleTrueFalse()}
              aria-expanded={isToggled}
              aria-label="Toggle menu"
            >
              <div
                className={`hamburger hamburger--slider ${isToggled ? " is-active" : ""
                  }`}
              >
                <div className="hamburger-box">
                  <div className="hamburger-inner" />
                </div>
              </div>
            </button>
          </nav>
        </div>
      </div>
    </header >
  );
};
export default Header;
