"use client";
import { useState, useCallback } from "react";
import Image from 'next-image-export-optimizer';
import Link from 'next/link';
import darkLogo from '@img/logo/Melkon Logo.png';

const MobileMenu = ({ dark }) => {
  const [toggle, setToggle] = useState(false);

  const toggleMenu = useCallback(() => {
    setToggle(prevToggle => !prevToggle);
  }, []);

  return (
    <nav className="melkontech_tm_mobile_menu w-full h-auto fixed top-0 left-0 hidden z-[10] middle:block" aria-label="Mobile Menu">
      <div className="mobile_menu_inner w-full h-auto clear-both float-left bg-white py-[15px] pr-[30px] pl-[40px]">
        <div className="mobile_in w-full h-auto clear-both float-left flex items-center justify-between">
          <div className="logo">
            <Link href="/" title="Melkon Hovhannisyan's official website" aria-label="Homepage">
              <Image
                placeholder="blur"
                src={dark ? "/assets/img/logo/logo.png" : darkLogo}
                width={200} // Explicit width
                alt="Melkon Hovhannisyan - CTO & Co-Founder Brand Logo"
                style={{ objectFit: "cover" }}
                priority
              />
            </Link>
          </div>
          <div className="trigger leading-[0]">
            <button
              className={`hamburger hamburger--slider ${toggle ? " is-active" : ""}`}
              onClick={toggleMenu}
              aria-expanded={toggle}
              aria-controls="dropdown"
              aria-label="Toggle menu"
            >
              <span className="hamburger-box">
                <span className="hamburger-inner" />
              </span>
            </button>
          </div>
        </div>
      </div>
      <div
        id="dropdown"
        className="dropdown w-full h-auto clear-both float-left bg-white hidden"
        style={{ display: toggle ? "block" : "none" }}
        aria-hidden={!toggle}
      >
        <div className="dropdown_inner w-full h-auto clear-both float-left py-[25px] px-[40px]">
          <ul className="anchor_nav" role="menu" aria-labelledby="dropdown">
            {[
              { id: 'home', label: 'Home' },
              { id: 'projects', label: 'Projects' },
              { id: 'skills', label: 'Skills' },
              { id: 'timeline', label: 'Timeline' },
              { id: 'connect', label: 'Connect' },
              { id: 'news', label: 'News' },
              { id: 'contact', label: 'Contact' }
            ].map(({ id, label }) => (
              <li key={id} className="float-left w-full" role="menuitem">
                <Link
                  className="text-black inline-block p-0 font-arimo font-medium"
                  href={`#${id}`}
                  onClick={() => setToggle(false)}
                  aria-label={`Go to ${label}`}
                >
                  {label}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default MobileMenu;
