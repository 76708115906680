"use client";

import { useState } from "react";
import TagManager from "react-gtm-module";
import RightArrowIcon from '@img/svg/right-arrow.svg'

const ClientFormHandler = () => {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");
    const [isSubmited, setIsSubmited] = useState(false);

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        setIsSubmited(true)
        TagManager.dataLayer({
            dataLayer: {
                event: "submit_form",

                formName: "Contact Form",
                name,
                email,
                message,
                // { name, email, message },
            },
        });
        // Handle form submission logic here
    };

    return (isSubmited ? <p>Your message has been successfully submitted. I will get in touch with you shortly, {name}.</p> : (
        <form
            onSubmit={handleSubmit}
            className="contact_form"
            id="contact_form"
            autoComplete="off"
        >
            <div
                className="returnmessage"
                data-success="Your message has been received, We will contact you soon."
            />
            <div className="empty_notice">
                <span>Please Fill Required Fields</span>
            </div>
            <div className="first w-full float-left">
                <ul>
                    <li className="w-full mb-[30px] float-left">
                        <input
                            id="name"
                            type="text"
                            placeholder="Name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            required
                            aria-required="true"
                        />
                    </li>
                    <li className="w-full mb-[30px] float-left">
                        <input
                            id="email"
                            type="email"
                            placeholder="Email"
                            value={email}
                            required
                            aria-required="true"
                            autoComplete="email"
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </li>
                </ul>
            </div>
            <div className="last">
                <textarea
                    id="message"
                    placeholder="Message"
                    value={message}
                    required
                    aria-required="true"
                    onChange={(e) => setMessage(e.target.value)}
                />
            </div>
            <button id="send_message" className="melkontech_tm_button melkontech_tm_button from-start-green to-end-yellowshape bg-gradient-to-br to-end-yellow" type="submit">
                <span>Connect</span>
                <RightArrowIcon
                    width={18}
                    className="svg inline-block"
                    alt="Right arrow"
                    height={18}
                />
            </button>
        </form>)
    );
};

export default ClientFormHandler;
